import * as React from "react";

import "./_style.scss";

const BorderLinearProgress = ({ done, authorisationBalance }) => {
  const [style, setStyle] = React.useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${(done * 100) / authorisationBalance}%`,
      max_width: `${(done * 100) / authorisationBalance}%`,
    };
    setStyle(newStyle);
  }, 200);
  return (
    <>
      <div className="progress">
        <div className="progress-done" style={style}>
          <span>{done === 0 ? "" : `${done}h`}</span>
        </div>
      </div>
      <div className="progress_bar_values">
        <div>
          <span>0h</span>
        </div>
        <div>
          <span>{authorisationBalance}h</span>
        </div>
      </div>
    </>
  );
};

export default BorderLinearProgress;
