import React from "react";
import { useSelector } from "react-redux";
import Calendar from "../../assets/icons/Calendar.png";
import "./_style.scss";
import DatePicker from "../../components/Form/DatePicker";
import Textarea from "../../components/Form/Textarea";
import SubTitle from "../../components/SubTitle/SubTitle.jsx";
import BorderLinearProgress from "./BorderLinearProgress";
import Pickers from "./MaterialUIPickers";

const AuthorisationDetails = ({
  formik,
  disabled,
  user,
  authorisationDetails,
}) => {
  const currentUser = useSelector((state) => state?.user?.fetchMe);
  const { myAuthorisation } = useSelector((state) => state.authorisation);
  const approvedRequest =
    authorisationDetails && user?.authorisationBalanceLeft != 0
      ? Math.abs(
          user?.authorisationBalance - user?.authorisationBalanceLeft
        ).toFixed(2)
      : myAuthorisation?.authorisations?.length > 0
      ? Math.abs(
          myAuthorisation?.authorisations[0]?.user?.authorisationBalance -
            myAuthorisation?.authorisations[0]?.user?.authorisationBalanceLeft
        ).toFixed(2)
      : 0;

  return (
    <>
      <div
        className={"negotiation-form-wrapper-details negotiation-form-wrapper "}
      >
        <div className="BorderLinearProgressWrapper">
          <SubTitle subTitle={"Authorisation  Balance"} />
          <BorderLinearProgress
            done={approvedRequest}
            authorisationBalance={
              user
                ? user?.authorisationBalance
                : currentUser?.authorisationBalance
            }
          />
        </div>
        <SubTitle subTitle={"Apply a new authorisation"} />
        <div className="datepicker_wrapper">
          <DatePicker
            calendarPopperPosition="bottom"
            formik={formik}
            type="date"
            label="Date"
            name="date"
            withIcon
            rightIcon={Calendar}
            placeholder="Select Date"
            className={
              disabled
                ? "diableddescription negotiation-date"
                : "costum-date-picker"
            }
            error={
              formik.touched?.date && formik.errors.date
                ? formik.errors.date
                : null
            }
          />
        </div>
        <div className="time_label">Time</div>
        <div>
          <Pickers
            errorStart={
              formik.touched?.startTime && formik.errors.startTime
                ? formik.errors.startTime
                : null
            }
            errorEnd={
              formik.touched?.endTime && formik.errors.endTime
                ? formik.errors.endTime
                : null
            }
            nameStart="startTime"
            nameEnd="endTime"
            formik={formik}
            disabled={disabled}
            className={disabled ? "diableddescription" : ""}
          />
        </div>
        <SubTitle subTitle={"Reason for authorisation"} />
        <Textarea
          className={disabled ? "diableddescription" : ""}
          type="textarea"
          placeholder="Add your description ..."
          name="description"
        />
      </div>
    </>
  );
};

export default AuthorisationDetails;
